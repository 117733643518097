<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('warehouse_service.registered_list') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
            <b-col xs="12" sm="12" md="6" lg="4" v-if="!authUser.warehouse_id">
                <b-form-group
                class="row"
                label-cols-sm="3"
                :label="$t('org_pro_division.division')"
                label-for="division_id"
                >
                <v-select name="division_id"
                    v-model="search.division_id"
                    label="text"
                    :options= divisionList
                    :reduce="item => item.value"
                    :placeholder="$t('globalTrans.select')"
                    />
                </b-form-group>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="4" v-if="!authUser.warehouse_id">
                <b-form-group
                class="row"
                label-cols-sm="3"
                :label="$t('warehouse_config.region')"
                label-for="region_id"
                >
                <v-select name="region_id"
                v-model="search.region_id"
                label="text"
                :options= regionLists
                :reduce="item => item.value"
                :placeholder="$t('globalTrans.select')"
                />
                </b-form-group>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="4" v-if="!authUser.warehouse_id">
                <b-form-group
                class="row"
                label-cols-sm="3"
                :label="$t('org_pro_district.district')"
                label-for="district_id"
                >
                <v-select name="district_id"
                v-model="search.district_id"
                label="text"
                :options= regionDistrictLists
                :reduce="item => item.value"
                :placeholder="districtLoading ? $t('globalTrans.loading') : $t('globalTrans.select')"
                />
                </b-form-group>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="4" v-if="!authUser.warehouse_id">
                <b-form-group
                class="row"
                label-cols-sm="3"
                :label="$t('org_pro_upazilla.upazilla')"
                label-for="upazilla_id"
                >
                <v-select name="upazilla_id"
                v-model="search.upazilla_id"
                label="text"
                :options= upazilaList
                :reduce="item => item.value"
                :placeholder="$t('globalTrans.select')"
                />
                </b-form-group>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="4" v-if="!authUser.warehouse_id">
                <b-form-group
                class="row"
                label-cols-sm="3"
                :label="$t('globalTrans.union')"
                label-for="union_id"
                >
                <v-select name="union_id"
                v-model="search.union_id"
                label="text"
                :options= unionList
                :reduce="item => item.value"
                :placeholder="$t('globalTrans.select')"
                />
                </b-form-group>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="4">
                <b-form-group
                    class="row"
                    label-cols-sm="3"
                    :label="$t('warehouse_config.warehouse_name')"
                    label-for="warehouse_name"
                    >
                    <b-form-select
                    plain
                    v-model="search.warehouse_id"
                    :options="warehouseList"
                    id="warehouse_id"
                    v-if="!authUser.warehouse_id"
                    ><template v-slot:first>
                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                </b-form-select>
                    <b-form-select
                    plain
                    v-model="search.warehouse_id"
                    :options="warehouseList"
                    id="warehouse_id"
                    v-else
                />
                </b-form-group>
            </b-col>
            <b-col class="text-right">
                <b-button type="button" variant="primary float-right" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
            </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
           <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('warehouse_service.registered_list') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button class="btn_add_new" v-b-modal.modal-4 @click="resetId">
              <i class="fas fa-plus"></i>{{ $t('warehouse_service.add_new_registration') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(warehouse_name)="data">
                      <span class="capitalize">{{ data.item.warehouse_name }}</span>
                    </template>
                    <template v-slot:cell(warehouse_type_name)="data">
                      <span class="capitalize">{{ data.item.warehouse_type_name }}</span>
                    </template>
                    <template v-slot:cell(upazilla_name)="data">
                      <span class="capitalize">{{ data.item.upazilla_name }}</span>
                    </template>
                    <template v-slot:cell(mobile_no)="data">
                     {{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(data.item.mobile_no, { useGrouping: false }) }}
                    </template>
                    <template v-slot:cell(status)="data">
                      {{ data.item.status ? $t('globalTrans.inactive') : $t('globalTrans.active') }}
                    </template>
                    <template v-slot:cell(action)="data">
                      <a href="javascript:" class="btn_table_action table_action_edit" title="Farmers/Beneficiaries Registration Edit" v-b-modal.modal-4 size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></a>
                      <!-- <b-button variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="ri-delete-bin-line m-0"></i></b-button> -->
                    </template>
                  </b-table>
                  <b-pagination
                    align="center"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-4" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <FormV :id="editItemId" :key="editItemId"/>
      </p>
    </b-modal>

  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import FormV from './Form'
import RestApi, { warehouseServiceBaseUrl, authServiceBaseUrl } from '@/config/api_config'
import { wareserviceRegistrationList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  mixins: [ModalBaseMasterList],
  name: 'UiDataTable',
  components: {
    FormV
  },
  data () {
    return {
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: this.$store.state.commonObj.perPage,
        slOffset: 1
      },
      search: {
        warehouse_name: '',
        warehouse_id: null,
        division_id: null,
        region_id: null,
        district_id: null,
        upazilla_id: null,
        union_id: null
      },
      rows: [],
      districtList: [],
      regionDistrictLists: [],
      upazilaList: [],
      unionList: [],
      warehouseList: [],
      districtLoading: false
    }
  },
  computed: {
   formTitle () {
      return (this.editItemId === 0) ? this.$t('warehouse_service.e-registration_entry') : this.$t('warehouse_service.e-registration_edit')
    },
    authUser () {
      return this.$store.state.Auth.authUser
    },
    isWareHouseUser () {
      return this.authUser.warehouse_id
    },
    // loading () {
    //   return this.$store.state.commonObj.loading
    // },
    // listReload () {
    //   return this.$store.state.commonObj.listReload
    // },
    // loadingState () {
    //   if (this.listReload) {
    //     return true
    //   } else if (this.loading) {
    //     return true
    //   } else {
    //     return false
    //   }
    // },
    // listData () {
    //   return this.$store.state.WarehouseService.registrations
    // },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
          { label: this.$t('warehouse_information.division'), class: 'text-center' },
          { label: this.$t('warehouse_config.region'), class: 'text-center' },
          { label: this.$t('warehouse_information.district'), class: 'text-center' },
          { label: this.$t('warehouse_information.upazilla'), class: 'text-center' },
          { label: this.$t('warehouse_config.warehouse_type'), class: 'text-center' },
          { label: this.$t('warehouse_service.name'), class: 'text-center' },
          { label: this.$t('warehouse_service.mobile_no'), class: 'text-center' },
          { label: this.$t('warehouse_service.service_type'), class: 'text-center' },
          { label: this.$t('warehouse_service.address'), class: 'text-center' },
          { label: this.$t('warehouse_service.father_name'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'division_name_bn' },
          { key: 'region_name_bn' },
          { key: 'distict_name_bn' },
          { key: 'upzila_name_bn' },
          { key: 'warehouse_type_name_bn' },
          { key: 'name_bn' },
          { key: 'mobile_no' },
          { key: 'service_type_bn' },
          { key: 'address_bn' },
          { key: 'father_name_bn' },
          { key: 'action' }
        ]
      } else {
        keys = [
        { key: 'index' },
          { key: 'division_name' },
          { key: 'region_name' },
          { key: 'distict_name' },
          { key: 'upzila_name' },
          { key: 'warehouse_type_name' },
          { key: 'name' },
          { key: 'mobile_no' },
          { key: 'service_type' },
          { key: 'address' },
          { key: 'father_name' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(document => document.status === 0)
    },
    warehouseTypeList: function () {
      return this.$store.state.warehouse.warehouseTypeList.filter(document => document.status === 0)
    },
    regionLists: function () {
        let regionList = this.$store.state.warehouse.regionList.filter(item => item.status === 0)
        if (this.isWareHouseUser) {
          regionList = regionList.filter(item => item.value === this.search.region_id)
        }
        return regionList
    }
  },
  watch: {
    // loadingState: function (newVal, oldVal) {
    //   if (newVal) {
    //     this.loadData()
    //   }
    // },
    'search.region_id': function (newVal, oldVal) {
      if (!this.isWareHouseUser) {
            this.getRegionDistrictList(newVal)
        }
    },
    'search.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'search.upazilla_id': function (newVal, oldVal) {
      this.unionList = this.getUnionList(newVal)
      this.warehouseList = this.getWarehouseName(newVal, 'UPZ')
    },
    'search.union_id': function (newVal, oldVal) {
      this.warehouseList = this.getWarehouseName(newVal, 'UP')
    }
  },
  created () {
    this.loadData()
  },
  mounted () {
    core.index()
    if (this.authUser.warehouse_id) {
      const warehouseId = this.authUser.warehouse_id
      this.warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(warehouseInfoItem => warehouseInfoItem.value === warehouseId)
      this.search.warehouse_id = warehouseId
    }
    if (this.isWareHouseUser) {
        this.search.division_id = this.authUser.office_detail.division_id
        this.search.region_id = this.$store.state.warehouse.regionDetailList.find(item => item.district_id === this.authUser.office_detail.district_id).region_id
        this.search.district_id = this.authUser.office_detail.district_id
        this.regionDistrictLists = this.$store.state.commonObj.districtList.filter(doc => doc.value === this.authUser.office_detail.district_id)
        this.search.upazilla_id = this.authUser.office_detail.upazilla_id ?? 0
        this.upazilaList = this.$store.state.commonObj.upazilaList.filter(doc => doc.value === this.authUser.office_detail.upazilla_id)
        this.search.union_id = this.authUser.office_detail.union_id ?? 0
        this.unionList = this.$store.state.commonObj.unionList.filter(doc => doc.value === this.authUser.office_detail.union_id)
        const warehouse = this.$store.state.warehouse.warehouseInfoList.find(doc => doc.office_id === this.authUser.office_detail.id)
        this.search.warehouse_id = warehouse.value
        this.warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(doc => doc.office_id === this.authUser.office_detail.id)
    }
    this.loadData()
  },
  methods: {
    // resetId () {
    //   this.editItemId = 0
    // },
    // testClick () {
    //   this.loadData()
    // },
    // default () {
    //   return {
    //     id: this.rows.length,
    //      name: '',
    //       name_bn: '',
    //       email: '',
    //       password: '',
    //       password_confirmation: '',
    //       user_type_id: '',
    //       mobile_no: '',
    //       service_type: '',
    //       service_type_bn: '',
    //       division_id: 0,
    //       region_id: 0,
    //       district_id: 0,
    //       upazilla_id: 0,
    //       union_id: 0,
    //       warehouse_type_id: 0,
    //       warehouse_id: 0,
    //       address: '',
    //       address_bn: '',
    //       father_name: '',
    //       father_name_bn: '',
    //       land_type_id: 0,
    //       land_area: ''
    //   }
    // },
    // paginationData (data) {
    //   this.pagination.currentPage = data.current_page
    //   this.pagination.totalRows = data.total
    //   this.pagination.slOffset = this.pagination.perPage * this.pagination.currentPage - this.pagination.perPage + 1
    // },
    searchData () {
      this.loadData()
    },
    async loadData () {
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
      const params = Object.assign({}, this.search, {
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage
      })
      const result = await RestApi.getData(authServiceBaseUrl, wareserviceRegistrationList, params)
      if (result.success) {
        this.$store.dispatch('setList', this.formatData(result.data.data))
        this.paginationData(result.data)
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    formatData (data) {
      const formattedData = data.map(item => {
        const divisionData = this.$store.state.commonObj.divisionList.find(division => division.value === item.division_id)
        const districtData = this.$store.state.commonObj.districtList.find(district => district.value === item.district_id)
        const upazilaData = this.$store.state.commonObj.upazilaList.find(upazilla => upazilla.value === item.upazilla_id)
        const regionData = this.$store.state.warehouse.regionList.find(region => region.value === item.region_id)
        const wareHouseTypeData = this.$store.state.warehouse.warehouseTypeList.find(wareHouseType => wareHouseType.value === item.warehouse_type_id)
        const tmpBindingData = {
          division_name: divisionData !== undefined ? divisionData.text_en : '',
          division_name_bn: divisionData !== undefined ? divisionData.text_bn : '',
          distict_name: districtData !== undefined ? districtData.text_en : '',
          distict_name_bn: districtData !== undefined ? districtData.text_bn : '',
          upzila_name: upazilaData !== undefined ? upazilaData.text_en : '',
          upzila_name_bn: upazilaData !== undefined ? upazilaData.text_bn : '',
          region_name: regionData !== undefined ? regionData.text_en : '',
          region_name_bn: regionData !== undefined ? regionData.text_bn : '',
          warehouse_type_name: wareHouseTypeData !== undefined ? wareHouseTypeData.text_en : '',
          warehouse_type_name_bn: wareHouseTypeData !== undefined ? wareHouseTypeData.text_bn : ''
        }
        return Object.assign({}, item, tmpBindingData)
      })
      return formattedData
    },
    getUnionList (upazilaId = null) {
      const unionList = this.$store.state.commonObj.unionList

      if (upazilaId) {
        return unionList.filter(union => union.upazilla_id === upazilaId)
      }

      return unionList
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)

      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }

      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)

      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }

      return upazilaList
    },
     getRegionDistrictList (regionId = null) {
      this.districtLoading = true
      RestApi.getData(warehouseServiceBaseUrl, '/master-warehouse-level/region-district-list').then(response => {
            if (response) {
                const dataObjectTemp = response.filter(document => document.region_id === regionId)
                const dataObject = dataObjectTemp.filter(item => item.status === 0)
                this.regionDistrictLists = dataObject.map(obj => {
                    if (this.$i18n.locale === 'bn') {
                        return { value: obj.district_id, text: this.$store.state.commonObj.districtList.find(document => document.value === obj.district_id).text_bn }
                    } else {
                        return { value: obj.district_id, text: this.$store.state.commonObj.districtList.find(document => document.value === obj.district_id).text_en }
                    }
                })
            }
        })
       this.districtLoading = false
    },
     getWarehouseName (Id = null, type) {
      const warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(item => item.status === 0)
        var list
      if (Id && type === 'UPZ') {
        list = warehouseList.filter(warehouse => warehouse.upazilla_id === Id)
      }

      if (Id && type === 'UP') {
        list = warehouseList.filter(warehouse => warehouse.union_id === Id)
      }

      return list
    }
  }
}
</script>
