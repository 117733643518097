<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loading">
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                      <b-row>
                        <b-col>
                          <ValidationProvider name="Name (En)" vid="name" rules="required">
                              <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="name"
                              slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                  {{ $t('warehouse_service.namef')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  id="name"
                                  v-model="registration.name"
                                  placeholder=""
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                              <div class="invalid-feedback">
                                  {{ errors[0] }}
                              </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col>
                            <ValidationProvider name="Name (Bn)" vid="name_bn" rules="required">
                              <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="name_bn"
                              slot-scope="{ valid, errors }"
                              >
                              <template v-slot:label>
                                  {{ $t('warehouse_service.name_bn')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                                  id="name_bn"
                                  v-model="registration.name_bn"
                                  placeholder=""
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  ></b-form-input>
                              <div class="invalid-feedback">
                                  {{ errors[0] }}
                              </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <ValidationProvider name="Beneficiary Type (En)" vid="service_type" rules="required">
                                <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="service_type"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{ $t('warehouse_service.service_typef')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    id="service_type"
                                    v-model="registration.service_type"
                                    placeholder=""
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col>
                          <ValidationProvider name="Beneficiary Type (Bn)" vid="service_type_bn" rules="required">
                                <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="service_type_bn"
                                slot-scope="{ valid, errors }"
                                >
                                 <template v-slot:label>
                                    {{ $t('warehouse_service.service_type_bn')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    id="service_type_bn"
                                    v-model="registration.service_type_bn"
                                    placeholder=""
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col v-if="authUser.warehouse_id == null">
                          <ValidationProvider name="Division" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="division"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('org_pro_division.division')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="registration.division_id"
                                :options="divisionList"
                                id="division_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col v-if="authUser.warehouse_id == null">
                          <ValidationProvider name="Region" vid='region_id' rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="region"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  {{ $t('warehouse_config.region')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                  plain
                                  v-model="registration.region_id"
                                  :options="regionList"
                                  id="region_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col v-if="authUser.warehouse_id == null">
                          <ValidationProvider name="District" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="district"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                    {{ $t('org_pro_district.district')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                    plain
                                    v-model="registration.district_id"
                                    :options="regionDistrictLists"
                                    id="district_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{ districtLoading ? $t('globalTrans.loading') : $t('globalTrans.select') }}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col v-if="authUser.warehouse_id == null">
                          <ValidationProvider name="Upazila" vid="upazilla_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="upazilla_id"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                    {{ $t('org_pro_upazilla.upazilla')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                    plain
                                    v-model="registration.upazilla_id"
                                    :options="upazilaList"
                                    id="upazilla_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col v-if="authUser.warehouse_id == null">
                          <ValidationProvider name="Union" rules="">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="union_id"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  {{ $t('org_pro_union.union')}}
                                  </template>
                                  <b-form-select
                                  plain
                                  v-model="registration.union_id"
                                  :options="unionList"
                                  id="union_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <b-col v-if="authUser.warehouse_id == null">
                          <ValidationProvider name="Warehouse Type" vid="warehouse_type_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="warehouse_type_id"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  {{ $t('warehouse_service.warehouse_type_id')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                  plain
                                  v-model="registration.warehouse_type_id"
                                  :options="warehouseTypeList"
                                  id="warehouse_type_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                              </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                       <b-row>
                          <b-col>
                            <ValidationProvider name="Warehouse Name" vid="warehouse_id" rules="required|min_value:1">
                                <b-form-group
                                class="row"
                                label-cols-sm="4"
                                :label="$t('warehouse_config.warehouse_name')"
                                label-for="warehouse_name"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                  {{ $t('warehouse_config.warehouse_name')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="registration.warehouse_id"
                                :options="warehouseNameList"
                                id="warehouse_id"
                                v-if="!authUser.warehouse_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                    <template v-slot:first>
                                    <b-form-select-option :value="0" >{{ warehouseLoading ? $t('globalTrans.loading') : $t('globalTrans.select') }}</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-select
                                    plain
                                    v-model="registration.warehouse_id"
                                    :options="warehouseNameList"
                                    id="warehouse_id"
                                    v-else
                                >
                                </b-form-select>
                                 <div class="invalid-feedback">
                                  {{ errors[0] }}
                                  </div>
                                </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col>
                            <ValidationProvider name="Address (En)" vid="address" rules="required">
                                  <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="address"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  {{ $t('warehouse_service.addressf')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                      id="address"
                                      v-model="registration.address"
                                      placeholder=""
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      ></b-form-input>
                                  <div class="invalid-feedback">
                                      {{ errors[0] }}
                                  </div>
                                  </b-form-group>
                              </ValidationProvider>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <ValidationProvider name="Address (Bn)" vid="address_bn" rules="">
                                <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="address_bn"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                  {{ $t('warehouse_service.address_bn')}}
                                </template>
                                <b-form-input
                                    id="address_bn"
                                    v-model="registration.address_bn"
                                    placeholder=""
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col>
                            <ValidationProvider name="Father Name (En)" vid="father_name" rules="">
                                  <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="father_name"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  {{ $t('warehouse_service.father_namef')}}
                                  </template>
                                  <b-form-input
                                      id="father_name"
                                      v-model="registration.father_name"
                                      placeholder=""
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      ></b-form-input>
                                  <div class="invalid-feedback">
                                      {{ errors[0] }}
                                  </div>
                                  </b-form-group>
                              </ValidationProvider>
                          </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                              <ValidationProvider name="Father Name (Bn)" vid="father_name_bn" rules="">
                                    <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="father_name_bn"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                  {{ $t('warehouse_service.father_name_bn') }}
                                  </template>
                                    <b-form-input
                                        id="father_name_bn"
                                        v-model="registration.father_name_bn"
                                        placeholder=""
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                             <b-col>
                              <ValidationProvider name="Mobile Number" vid="mobile_no" rules="digits:11|required">
                                  <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="mobile_no"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                      {{ $t('warehouse_service.mobile_no')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                      type="number"
                                      id="mobile_no"
                                      v-model="registration.mobile_no"
                                      placeholder=""
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      ></b-form-input>
                                  <div class="invalid-feedback">
                                      {{ errors[0] }}
                                  </div>
                                  </b-form-group>
                              </ValidationProvider>
                            </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                                <ValidationProvider name="Land Type" vid="land_type_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="land_type_id"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                    {{ $t('warehouse_service.land_type_id')}} <span class="text-danger">*</span>
                                    </template>
                                    <b-form-select
                                    plain
                                    v-model="registration.land_type_id"
                                    :options="landTypeList"
                                    id="land_type_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                    </b-form-select>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                    </div>
                                </b-form-group>
                                </ValidationProvider>
                          </b-col>
                          <b-col>
                            <ValidationProvider name="Land Area" vid="land_area" rules="required|max_value:5">
                                <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="land_area"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('warehouse_service.land_area')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    type="number"
                                    id="land_area"
                                    v-model="registration.land_area"
                                    placeholder=""
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                              <ValidationProvider name="Remarks (En)" vid="remarks">
                                  <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="remarks"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                  {{ $t('warehouse_service.remarksf')}}
                                  </template>
                                  <b-form-textarea
                                      id="remarks"
                                      v-model="registration.remarks"
                                      placeholder=""
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      ></b-form-textarea>
                                  <div class="invalid-feedback">
                                      {{ errors[0] }}
                                  </div>
                                  </b-form-group>
                              </ValidationProvider>
                            </b-col>
                          <b-col>
                              <ValidationProvider name="Remarks Bn" vid="remarks">
                                    <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="remarks_bn"
                                    slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                    {{ $t('warehouse_service.remarks_bn')}}
                                    </template>
                                    <b-form-textarea
                                        id="remarks_bn"
                                        v-model="registration.remarks_bn"
                                        placeholder=""
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-textarea>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                    </b-form-group>
                              </ValidationProvider>
                          </b-col>
                        </b-row>
                        <b-row>
                           <b-col>
                            <ValidationProvider name="Nid Number" vid="nid" rules="">
                                <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="nid"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{ $t('warehouse_service.nid')}}
                                </template>
                                <b-form-input
                                   type="number"
                                    id="nid"
                                    v-model="registration.nid"
                                    placeholder=""
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col>
                            <ValidationProvider name="Password" vid="password" :rules="id ? '' : 'required|min:6'">
                                <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="password"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{ $t('warehouse_service.password')}} <span class="text-danger" v-if="!id">*</span>
                                </template>
                                <b-form-input
                                    id="password"
                                    type="password"
                                    v-model="registration.password"
                                    placeholder=""
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <ValidationProvider name="Confirm password" vid="password_confirmation" :rules="id ? '': 'required|confirmed:password'">
                                <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="password_confirmation"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                  {{ $t('warehouse_service.password_confirmation')}}<span class="text-danger" v-if="!id">*</span>
                                </template>
                                <b-form-input
                                    id="password_confirmation"
                                    type="password"
                                    v-model="registration.password_confirmation"
                                    placeholder=""
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                    <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                              </ValidationProvider>
                          </b-col>
                          <b-col></b-col>
                        </b-row>
                        <div class="row">
                          <div class="col-sm-3"></div>
                          <div class="col text-right">
                            <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                            &nbsp;
                            <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                          </div>
                      </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>

import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { warehouseServiceBaseUrl, authServiceBaseUrl } from '../../../../../config/api_config'
import { wareserviceRegistrationStore, wareserviceRegistrationUpdate } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      this.registration = this.getWarehouseInformationData()
    }
  },
  mounted () {
    core.index()
    if (this.authUser.warehouse_id) {
      const warehouseId = this.authUser.warehouse_id
      this.warehouseNameList = this.$store.state.warehouse.warehouseInfoList.filter(warehouseInfoItem => warehouseInfoItem.value === warehouseId)
      this.registration.warehouse_id = warehouseId
    }
    if (this.isWareHouseUser) {
        this.registration.division_id = this.authUser.office_detail.division_id
        this.registration.region_id = this.$store.state.warehouse.regionDetailList.find(item => item.district_id === this.authUser.office_detail.district_id).region_id
        this.registration.district_id = this.authUser.office_detail.district_id
        this.regionDistrictLists = this.$store.state.commonObj.districtList.filter(doc => doc.value === this.authUser.office_detail.district_id)
        this.registration.upazilla_id = this.authUser.office_detail.upazilla_id ?? 0
        this.upazilaList = this.$store.state.commonObj.upazilaList.filter(doc => doc.value === this.authUser.office_detail.upazilla_id)
        this.registration.union_id = this.authUser.office_detail.union_id ?? 0
        this.unionList = this.$store.state.commonObj.unionList.filter(doc => doc.value === this.authUser.office_detail.union_id)
        const warehouse = this.$store.state.warehouse.warehouseInfoList.find(doc => doc.office_id === this.authUser.office_detail.id)
        this.registration.warehouse_id = warehouse.value
        this.warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(doc => doc.office_id === this.authUser.office_detail.id)
        this.registration.warehouse_type_id = warehouse.warehouse_type_id
        this.warehouseTypeList = this.$store.state.warehouse.warehouseTypeList.filter(doc => doc.value === warehouse.warehouse_type_id)
    }
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      registration: {
          name: '',
          name_bn: '',
          email: '',
          password: '',
          password_confirmation: '',
          user_type_id: '',
          mobile_no: '',
          service_type: '',
          service_type_bn: '',
          division_id: 0,
          region_id: 0,
          district_id: 0,
          upazilla_id: 0,
          union_id: 0,
          warehouse_type_id: 0,
          warehouse_id: 0,
          address: '',
          address_bn: '',
          father_name: '',
          father_name_bn: '',
          land_type_id: 0,
          land_area: '',
          nid: ''
      },
      districtList: [],
      upazilaList: [],
      unionList: [],
     //  warehouseTypeList: [],
      warehouseNameList: [],
      regionDistrictLists: [],
      landTypeList: [
        { text: (this.$i18n.locale === 'bn') ? 'নিজস্ব' : 'Own', value: 1 },
        { text: (this.$i18n.locale === 'bn') ? 'ইজারা' : 'Lease', value: 1 }
      ],
      districtLoading: false,
      warehouseLoading: false
    }
  },
  computed: {
    authUser () {
      return this.$store.state.Auth.authUser
    },
    isWareHouseUser () {
      return this.authUser.warehouse_id
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList
    },
    regionList: function () {
        let regionList = this.$store.state.warehouse.regionList.filter(item => item.status === 0)
        if (this.isWareHouseUser) {
          regionList = regionList.filter(item => item.value === this.registration.region_id)
        }
        return regionList
    },
    warehouseTypeList: function () {
        let typeList = this.$store.state.warehouse.warehouseTypeList.filter(item => item.status === 0)
        if (this.isWareHouseUser) {
          typeList = typeList.filter(item => item.value === this.registration.warehouse_type_id)
        }
        return typeList
    },
    loading () {
      return this.$store.state.commonObj.loading
    },
    listReload () {
      return this.$store.state.commonObj.listReload
    }
  },
  watch: {
    'registration.division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
     'registration.region_id': function (newVal, oldVal) {
        if (!this.isWareHouseUser) {
            this.getRegionDistrictList(newVal)
        }
    },
    'registration.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'registration.upazilla_id': function (newVal, oldVal) {
      this.unionList = this.getUnionList(newVal)
      // this.getWarehouseName(this.registration.typeId, newVal)
    },
    'registration.warehouse_type_id': function (newVal, oldVal) {
      this.getWarehouseName(newVal, this.registration.upazilla_id)
    }
  },
  methods: {
    getWarehouseInformationData () {
      // return this.$store.state.WareHouseConfig.warehouseInformations.find(item => item.id === this.id)
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async register () {
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      let result = null
      const loadinState = { loading: true, listReload: false }
      this.$store.dispatch('mutateCommonProperties', loadinState)
      if (this.id) {
        result = await RestApi.putData(authServiceBaseUrl, `${wareserviceRegistrationUpdate}/${this.id}`, this.registration)
      } else {
        result = await RestApi.postData(authServiceBaseUrl, wareserviceRegistrationStore, this.registration)
      }

      if (result.success) {
        this.$toast.success({
          title: 'Success',
          message: this.id ? 'Data Updated successfully' : 'Data saved successfully',
          color: '#D6E09B'
        })
        // this.$socket.emit('send-notification', result.notification)
        this.$bvModal.hide('modal-4')
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      }
    },
   getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList

      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }

      return districtList
    },
    async getRegionDistrictList (regionId = null) {
      this.districtLoading = true
      await RestApi.getData(warehouseServiceBaseUrl, '/master-warehouse-level/region-district-list').then(response => {
        if (response) {
          const dataObject = response.filter(document => document.region_id === regionId)
          this.regionDistrictLists = dataObject.map(obj => {
              if (this.$i18n.locale === 'bn') {
                  return { value: obj.district_id, text: this.$store.state.commonObj.districtList.find(document => document.value === obj.district_id).text_bn }
              } else {
                  return { value: obj.district_id, text: this.$store.state.commonObj.districtList.find(document => document.value === obj.district_id).text }
              }
          })
          this.districtLoading = false
        } else {
          this.districtLoading = false
        }
      })
    },
   getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList

      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }

      return upazilaList
    },
    getUnionList (upazilaId = null) {
      const unionList = this.$store.state.commonObj.unionList
      if (upazilaId) {
        return unionList.filter(union => union.upazilla_id === upazilaId)
      }

      return unionList
    },
     async getWarehouseName (typeId, upazillaId) {
       this.warehouseNameList = []
      this.warehouseLoading = true
      await RestApi.getData(warehouseServiceBaseUrl, 'master-warehouse-info/list-all').then(response => {
        if (response.success) {
          let dataObject = []
          if (typeId && upazillaId !== 0) {
             dataObject = response.data.filter(warehouseinfo => warehouseinfo.warehouse_type_id === typeId && warehouseinfo.upazilla_id === upazillaId)
          } else if (upazillaId !== 0) {
             dataObject = response.data.filter(warehouseinfo => warehouseinfo.upazilla_id === upazillaId)
          } else {
             dataObject = response.data.filter(warehouseinfo => warehouseinfo.warehouse_type_id === typeId)
          }
          if (this.registration.union_id) {
            dataObject = dataObject.filter(item => item.union_id === this.registration.union_id)
          }
          this.warehouseNameList = dataObject.map((obj, index) => {
            if (this.$i18n.locale === 'bn') {
              return { value: obj.id, text: obj.warehouse_name_bn }
            } else {
              return { value: obj.id, text: obj.warehouse_name }
            }
          })
          this.warehouseLoading = false
        } else {
          this.warehouseLoading = false
        }
      })
    }
  }
}

</script>
